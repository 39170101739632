import React from "react"

// Types
import { IIconProps } from "./Icon"

const Company: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 28 24"
    className={className}
    fill="none"
  >
    <path
      d="M19.014 23.254h5.89c1.453 0 2.32-.93 2.32-2.468V7.898c0-1.55-.867-2.48-2.32-2.48h-5.195v15.368a4.55 4.55 0 0 1-.695 2.468Zm3.217-12.247V9.32c0-.214.14-.364.353-.364h1.742c.214 0 .353.15.353.364v1.688c0 .225-.139.374-.353.374h-1.742c-.214 0-.353-.15-.353-.374Zm0 4.179v-1.689c0-.224.14-.374.353-.374h1.742c.214 0 .353.15.353.374v1.689c0 .213-.139.363-.353.363h-1.742c-.214 0-.353-.15-.353-.363Zm0 4.168v-1.689c0-.224.14-.374.353-.374h1.742c.214 0 .353.15.353.374v1.689c0 .224-.139.363-.353.363h-1.742c-.214 0-.353-.139-.353-.363ZM.777 20.785c0 1.54.856 2.47 2.31 2.47h12.656c1.453 0 2.32-.93 2.32-2.47V2.47c0-1.54-.867-2.469-2.32-2.469H3.086C1.633 0 .777.93.777 2.469v18.317ZM5.32 6.038v-2.03c0-.268.171-.45.428-.45h2.18c.257 0 .439.182.439.45v2.03c0 .267-.182.449-.438.449H5.748c-.257 0-.428-.182-.428-.45Zm5.217 0v-2.03c0-.268.17-.45.427-.45h2.181c.257 0 .428.182.428.45v2.03c0 .267-.171.449-.428.449h-2.18c-.257 0-.428-.182-.428-.45Zm-5.217 4.37v-2.03c0-.267.171-.449.428-.449h2.18c.257 0 .439.182.439.45v2.03c0 .267-.182.449-.438.449H5.748c-.257 0-.428-.182-.428-.45Zm5.217 0v-2.03c0-.267.17-.449.427-.449h2.181c.257 0 .428.182.428.45v2.03c0 .267-.171.449-.428.449h-2.18c-.257 0-.428-.182-.428-.45ZM5.32 14.78v-2.03c0-.268.171-.45.428-.45h2.18c.257 0 .439.182.439.45v2.03c0 .267-.182.448-.438.448H5.748c-.257 0-.428-.181-.428-.448Zm5.217 0v-2.03c0-.268.17-.45.427-.45h2.181c.257 0 .428.182.428.45v2.03c0 .267-.171.448-.428.448h-2.18c-.257 0-.428-.181-.428-.448Zm-4.981 6.422v-3.26c0-.512.342-.833.844-.833h6.018c.599 0 .92.32.92.834v3.26H5.556Z"
      fill={color}
    />
  </svg>
)

export default Company
