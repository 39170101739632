import React, { useState } from "react"
import styled from "styled-components"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import ContentWrapper from "./ContentWrapper"
import Testimonial from "./Testimonial"
import Icon from "./icons/Icon"

// Types
import { ITestimonials } from "../prismic-types/homepage"

const Testimonials: React.FC<ITestimonials> = ({
  title,
  description,
  testimonials,
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [isHovered, setIsHovered] = useState<"" | "left" | "right">("")

  return (
    <Container>
      <StyledContentWrapper>
        {title && (
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        {description && (
          <Text dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <InnerWrapper>
          <NavButton
            className="large-screen-nav"
            disabled={activeSlide === 0}
            onClick={() => setActiveSlide(activeSlide - 1)}
            onMouseEnter={() => setIsHovered("left")}
            onMouseLeave={() => setIsHovered("")}
          >
            <Icon
              icon="arrowRight"
              direction="left"
              color={isHovered === "left" ? colors.white : colors.purpleLight}
              width={24}
              height={24}
            />
          </NavButton>
          <TestimonialsWrapper>
            <Testimonial
              key={testimonials[activeSlide].client.name}
              {...testimonials[activeSlide]}
            />
            {testimonials.length > 1 && (
              <Nav>
                <NavButton
                  onMouseEnter={() => setIsHovered("left")}
                  onMouseLeave={() => setIsHovered("")}
                  disabled={activeSlide === 0}
                  onClick={() => setActiveSlide(activeSlide - 1)}
                >
                  <Icon
                    icon="arrowRight"
                    direction="left"
                    color={
                      isHovered === "left" ? colors.white : colors.purpleLight
                    }
                    width={24}
                    height={24}
                  />
                </NavButton>
                <NavButton
                  onMouseEnter={() => setIsHovered("right")}
                  onMouseLeave={() => setIsHovered("")}
                  disabled={activeSlide === testimonials.length - 1}
                  onClick={() => setActiveSlide(activeSlide + 1)}
                >
                  <Icon
                    icon="arrowRight"
                    direction="right"
                    color={
                      isHovered === "right" ? colors.white : colors.purpleLight
                    }
                    width={24}
                    height={24}
                  />
                </NavButton>
              </Nav>
            )}
          </TestimonialsWrapper>
          <NavButton
            className="large-screen-nav"
            disabled={activeSlide === testimonials.length - 1}
            onClick={() => setActiveSlide(activeSlide + 1)}
            onMouseEnter={() => setIsHovered("right")}
            onMouseLeave={() => setIsHovered("")}
          >
            <Icon
              icon="arrowRight"
              direction="right"
              color={isHovered === "right" ? colors.white : colors.purpleLight}
              width={24}
              height={24}
            />
          </NavButton>
        </InnerWrapper>
      </StyledContentWrapper>
    </Container>
  )
}

const Container = styled.section`
  padding: 64px 0;
  position: relative;
  z-index: 1;

  ${mq.from.M`
    padding: 88px 0;
  `}

  ${mq.from.L`
    padding: 120px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`

const InnerWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

const Title = styled.div`
  color: ${colors.main["200"]};
  ${textStyles.titleL};
  > h3 {
    ${textStyles.titleL};
  }
  margin: 0 0 32px;

  ${mq.from.M`
    margin-bottom: 40px;
  `}
`

const Text = styled.div`
  ${textStyles.body};

  ${mq.from.M`
    margin: 0 0 32px;
  `}

  > p:last-child {
    margin-bottom: 0;
  }
`

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Nav = styled.div`
  display: none; // Hidden by default
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  ${mq.from.M`
    margin: 48px 0;
  `}

  ${mq.to.M`
  display: inline-flex;
`}
`

const NavButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: center;
  background-color: ${colors.violetLight};
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(42, 9, 134, 0.1);

  &:disabled {
    opacity: 0.5;
    background-color: ${colors.whiteGrey};
    cursor: default;

    &:hover {
      background-color: ${colors.whiteGrey};
    }
  }

  ${mq.from.M`
    width: 52px;
    height: 52px;
    padding: 14px;
  `}

  ${mq.to.M`
  &.large-screen-nav {
    display: none;
  }
  `}

  &:first-of-type {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${colors.purpleLight};
  }
`

export default Testimonials
