import React from "react"

// Types
import { IIconProps } from "./Icon"

const Play: React.FCS<IIconProps> = ({ className, width, height, color }) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 36 42"
    className={className}
    fill="none"
  >
    <path
      d="M34.75 19.616c1 .577 1 2.02 0 2.598L3.377 40.327a1.5 1.5 0 01-2.25-1.3V2.803a1.5 1.5 0 012.25-1.299L34.75 19.616z"
      fill={color}
      stroke={color}
    />
  </svg>
)

export default Play
