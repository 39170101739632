import React from "react"

// Types
import { IIconProps } from "./Icon"

const Cross: React.FCS<IIconProps> = ({ className, width, height, color }) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 20 20"
    className={className}
    fill="none"
  >
    <path
      d="M18 2L2 18"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2L18 18"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cross
