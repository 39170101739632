import { Background } from "../prismic-types/homepage"

/**
 * The function `generateBackgroundStyles` takes a `Background` object as input and returns a string of
 * CSS styles based on the type of background.
 * @param {Background} background - The `background` parameter is an object that represents the
 * background style. It has the following properties:
 * @returns The function `generateBackgroundStyles` returns a string representing the CSS styles for
 * the given background type.
 */
export const generateBackgroundStyles = (background: Background): string => {
  switch (background.type) {
    case "image":
      return `
        background-image: url(${background.imageUrl});
        background-size: cover;
        background-position: center 50%;
        background-repeat: no-repeat;
        `

    case "plain":
      return `background-color: ${background.color};`

    case "pattern":
      return `
          background-image: url(${background.patternUrl});
          background-color: ${background.backgroundColor};
          background-size: cover;
          background-position: center 50%;
          background-repeat: no-repeat;
        `

    case "gradient":
      return `background: ${background.gradientValues};`

    case "imageWithGradient":
      return `
          background: radial-gradient(circle, ${
            background.gradientStartColor
          } ${background.gradientStartPercentage ?? 0}%, ${
        background.gradientEndColor
      } ${background.gradientEndPercentage ?? 100}%),
            url(${background.imageUrl});
          background-size: cover;
          background-position: center 50%;
          background-repeat: no-repeat;
        `

    default:
      return ""
  }
}
