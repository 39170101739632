import React from "react"
import styled from "styled-components"

// Types
import { IIconProps } from "./Icon"

const Loading: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <Svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 120 120"
    fill="transparent"
    className={className}
  >
    <circle
      cx="60"
      cy="60"
      r="48"
      stroke={color}
      strokeWidth="24"
      strokeMiterlimit="16"
      strokeDasharray="8 8"
    />
  </Svg>
)

const Svg = styled.svg`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 3.2s linear infinite;
`

export default Loading
