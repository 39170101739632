import React from "react"

// Types
import { IIconProps } from "./Icon"

const User: React.FCS<IIconProps> = ({ className, width, height, color }) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 21 21"
    className={className}
    fill="none"
  >
    <path
      d="M10.5 10.232c2.498 0 4.58-2.23 4.58-5.087 0-2.778-2.093-4.963-4.58-4.963-2.477 0-4.582 2.208-4.57 4.974 0 2.846 2.07 5.077 4.57 5.077ZM3.532 20.864h13.935c1.925 0 2.578-.626 2.578-1.708 0-2.914-3.715-6.92-9.545-6.92-5.843 0-9.546 4.006-9.546 6.92 0 1.082.653 1.708 2.578 1.708Z"
      fill={color}
    />
  </svg>
)

export default User
