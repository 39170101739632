import React from "react"
import styled from "styled-components"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import { generateBackgroundStyles } from "../styles/stylingHelpers"
import textStyles, { fontWeights } from "../styles/textStyles"

// Components
import ContentWrapper from "./ContentWrapper"
import Button, { Variant } from "./atoms/Button"

// Constants
import zap from "../images/zap.svg"

// Types
import { Background, IBottomSection } from "../prismic-types/homepage"

interface IProps extends IBottomSection {
  buttonClick?: () => void
  textColor?: string
  titleColor?: string
  className?: string
  background: Background
  sectionId: string
  buttonVariant: Variant
  titleFontSize?: string
}

const BottomSection: React.FC<IProps> = ({
  title,
  text,
  buttonLabel,
  className,
  textColor = colors.black,
  titleColor = colors.black,
  buttonClick,
  buttonVariant,
  background,
  label,
  sectionId,
  titleFontSize = "64px",
  buttonLink,
}) => {
  const { getLocalizedLink } = useLocale()
  return (
    <Container id={sectionId} className={className} background={background}>
      <ContentWrapper>
        <InnerWrapper smaller={false}>
          <TextWrapper titleFontSize={titleFontSize}>
            {label && <Label>{label}</Label>}
            {title && (
              <Title color={titleColor} as={"h1"}>
                {title}
              </Title>
            )}
            {text && (
              <Text
                color={textColor}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            <ButtonRow>
              {buttonLabel && (
                <Button
                  label={buttonLabel}
                  onClick={buttonClick}
                  href={getLocalizedLink(buttonLink || "")}
                  variant={buttonVariant}
                />
              )}
            </ButtonRow>
          </TextWrapper>
        </InnerWrapper>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section<{
  color?: string
  background: Background
}>`
  position: relative;
  padding: 96px 0;
  ${({ background }) => generateBackgroundStyles(background)}
  color: ${colors.text};

  ${mq.from.S`
    padding: 104px 0 96px;
  `}

  ${mq.from.M`
    padding: 168px 0 128px;
  `}
`

const InnerWrapper = styled.div<{ smaller?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq.from.M`
    ${({ smaller }: { smaller: boolean }) =>
      smaller &&
      `
       width: calc(10/12*100%);
       margin-left: auto;
       margin-right: auto;
    `}
  `}
`

const Label = styled.label`
  ${textStyles.titleS};
  margin-bottom: 32px;
`

const TextWrapper = styled.div<{ titleFontSize: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1;
  padding-horizontal: 16px;

  ${mq.from.S`
    max-width: 777px;
  `}

  > h1 {
    ${textStyles.titleXL};
    margin-bottom: 16px;

    ${mq.from.S`
      margin-bottom: 24px;
    `}

    ${mq.from.M`
     font-size: ${({ titleFontSize }: { titleFontSize: string }) =>
       titleFontSize} !important;
    `}
  }

  > h3 {
    ${textStyles.titleL};
    margin-bottom: 16px;

    ${mq.from.S`
      margin-bottom: 24px;
    `}
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > button:nth-of-type(2) {
    margin-top: 8px;
  }

  ${mq.from.XS`
    flex-direction: row;
    align-items: center;

    > button:nth-of-type(2) {
      margin: 0 0 0 16px;
    }
  `}
`

const Title = styled.span<{ color?: string }>`
  text-align: center;
  ${({ color }) =>
    `color: ${color};
  `}
`

const Text = styled.div<{ color?: string }>`
  text-align: center;
  ${({ color }) =>
    `color: ${color};
`}

  > p {
    ${textStyles.body};
    font-size: 20px !important;
  }

  > b {
    font-weight: ${fontWeights.bold};
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

export default BottomSection
