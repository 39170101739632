import React from "react"

// Types
import { IIconProps } from "./Icon"

const Facebook: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 34 34"
    className={className}
    fill="none"
  >
    <path
      d="M19.741 33.779zM20.081 33.72c-.068.013-.135.025-.203.036l.203-.035zM18.935 33.89zM19.261 33.85zM20.544 33.629zM21.692 33.343l-.144.04.145-.04zM21.345 33.438l-.157.041.157-.04zM20.89 33.552l-.167.038.168-.038zM18.43 33.94zM34 17c0-9.387-7.613-17-17-17S0 7.613 0 17s7.613 17 17 17c.1 0 .2-.002.299-.004V20.762h-3.652v-4.256h3.652v-3.132c0-3.633 2.217-5.61 5.458-5.61 1.552 0 2.885.116 3.274.167v3.797h-2.234c-1.763 0-2.105.838-2.105 2.067v2.71h4.216l-.55 4.257h-3.666v12.581C28.798 31.306 34 24.76 34 17zM18.123 33.962zM17.581 33.989z"
      fill={color}
    />
  </svg>
)

export default Facebook
