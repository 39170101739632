import React from "react"

// Types
import { IIconProps } from "./Icon"

const Twitter: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 34 33"
    className={className}
    fill="none"
  >
    <path
      d="M17 0C7.613 0 0 7.389 0 16.5S7.613 33 17 33s17-7.389 17-16.5S26.387 0 17 0zm7.762 12.865c.008.162.011.325.011.49 0 5.004-3.925 10.775-11.102 10.775-2.204 0-4.254-.627-5.98-1.701.304.035.615.052.93.052a7.98 7.98 0 004.846-1.621c-1.708-.03-3.148-1.126-3.645-2.63a3.999 3.999 0 001.762-.066c-1.785-.347-3.13-1.878-3.13-3.712v-.048a3.965 3.965 0 001.768.473c-1.048-.678-1.737-1.838-1.737-3.152 0-.694.193-1.344.529-1.904 1.924 2.292 4.8 3.799 8.043 3.957a3.68 3.68 0 01-.102-.863c0-2.09 1.748-3.787 3.903-3.787 1.122 0 2.136.46 2.848 1.197a7.972 7.972 0 002.477-.92 3.824 3.824 0 01-1.715 2.095 7.967 7.967 0 002.24-.596 7.805 7.805 0 01-1.946 1.96z"
      fill={color}
    />
  </svg>
)

export default Twitter
