import React from "react"
import styled from "styled-components"

// Styles
import { fastTransition } from "../../styles/animations"

// Types
import { IIconProps } from "./Icon"

const Chevron: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
  direction = "right",
}) => (
  <Svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 24 41"
    direction={direction}
    className={className}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.978 1.234l18.04 17.828c.76.75.76 1.963 0 2.716L4.977 39.606a2.84 2.84 0 01-3.981 0 2.762 2.762 0 010-3.934l15.43-15.253L.998 5.169a2.764 2.764 0 010-3.935 2.84 2.84 0 013.981 0z"
      fill={color}
    />
  </Svg>
)

const Svg = styled.svg<{
  direction: "right" | "left" | "up" | "down" | undefined
}>`
  transition: transform ${fastTransition};

  ${({ direction }) =>
    direction === "left"
      ? `
    transform: rotate(180deg);
  `
      : direction === "up"
      ? `transform: rotate(270deg)`
      : direction === "down"
      ? "transform: rotate(90deg)"
      : "transform: rotate(0)"};
`

export default Chevron
