import React from "react"

// Types
import { IIconProps } from "./Icon"

const Checkmark: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 20 20"
    className={className}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9015 4.6893c-.4949-.495-1.2954-.495-1.7904 0l-7.7194 7.7116-2.5029-2.5028c-.4949-.495-1.2954-.495-1.7903 0-.495.4949-.495 1.2954 0 1.7903l3.402 3.402c.4948.4949 1.2954.4949 1.7903 0l8.6107-8.6107c.4949-.495.4949-1.2955 0-1.7904z"
      fill={color}
    />
  </svg>
)

export default Checkmark
