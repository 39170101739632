import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import GatsbyImage from "gatsby-image"

// Styling
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import colors from "../styles/colors"

// Types
import { ITestimonial } from "../prismic-types/homepage"

interface IProps extends ITestimonial {
  className?: string
}

const Testimonial: React.FC<IProps> = ({
  image,
  client,
  quote,
  className,
  coverImage,
}) => (
  <Container className={className}>
    <ImageWrapper>
      {coverImage?.url && (
        <GatsbyImage fluid={coverImage?.url} alt={coverImage?.alt} />
      )}
    </ImageWrapper>
    <InnerWrapper>
      <ProfileWrapper>
        {image && (
          <StyledImage fluid={image.url} role="img" aria-label={image.alt} />
        )}
        <TextWrapper>
          <Name>{client.name}</Name>
          <Position>{client.position}</Position>
        </TextWrapper>
      </ProfileWrapper>
      <Quote dangerouslySetInnerHTML={{ __html: quote }} />
    </InnerWrapper>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`
const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  align-items: center;

  ${mq.to.M`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `}
`

const ImageWrapper = styled.div<{ mirrored?: boolean }>`
  width: 100%;
  height: auto;

  img {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  min-width: 25%;

  ${mq.to.M`
  flex-direction: column;
  row-gap: 48px;
  align-items: center;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;

  ${mq.from.M`
    justify-content: start;
    align-items: flex-start;
  `}
`

const StyledImage = styled(BackgroundImage)`
  min-width: 120px;
  height: 120px;

  ${mq.from.M`
    min-width: 70px;
    height: 69px;
    margin-bottom: 0px;
  `}
  border-radius: 50%;
`

const Name = styled.p`
  ${textStyles.titleS};
  color: ${colors.main["200"]};
  text-align: center;

  ${mq.from.M`
    text-align: left;
    font-size: 20px !important;
  `}
`

const Position = styled.span`
  color: ${colors.main["200"]};
  text-align: center;
  opacity: 0.5;

  ${mq.from.M`
    text-align: left;
  `}
`

const Quote = styled.div`
  > p {
    ${textStyles.titleM};
    color: ${colors.purpleLight};
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mq.to.M`
  > p {
    text-align: center;
  }
  text-align: center;
  `}
`

export default Testimonial
