import React from "react"

// Types
import { IIconProps } from "./Icon"

const Instagram: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 35 34"
    className={className}
    fill="none"
  >
    <path
      d="M20.254 17a3.254 3.254 0 11-6.508 0 3.254 3.254 0 016.508 0z"
      fill={color}
    />
    <path
      d="M24.61 11.243a3.023 3.023 0 00-.73-1.123 3.022 3.022 0 00-1.123-.73c-.344-.134-.86-.293-1.812-.336-1.03-.047-1.339-.057-3.945-.057-2.607 0-2.915.01-3.945.057-.951.043-1.468.202-1.812.336a3.02 3.02 0 00-1.123.73 3.026 3.026 0 00-.73 1.123c-.134.344-.293.86-.336 1.812-.047 1.03-.057 1.338-.057 3.945 0 2.607.01 2.915.057 3.945.043.951.202 1.468.336 1.812.157.424.406.808.73 1.123.315.324.699.573 1.123.73.344.134.86.293 1.812.336 1.03.047 1.338.057 3.945.057 2.607 0 2.915-.01 3.944-.057.952-.043 1.47-.202 1.813-.336a3.233 3.233 0 001.853-1.853c.133-.344.292-.86.336-1.812.047-1.03.057-1.338.057-3.945 0-2.607-.01-2.915-.057-3.945-.043-.951-.202-1.468-.336-1.812zM17 22.013a5.013 5.013 0 110-10.026 5.013 5.013 0 010 10.025zm5.21-9.053a1.171 1.171 0 110-2.343 1.171 1.171 0 010 2.343z"
      fill={color}
    />
    <path
      d="M17 0C7.613 0 0 7.613 0 17s7.613 17 17 17 17-7.613 17-17S26.387 0 17 0zm9.703 21.025c-.047 1.039-.213 1.748-.454 2.37a4.99 4.99 0 01-2.855 2.854c-.62.241-1.33.406-2.37.454-1.04.047-1.373.059-4.024.059s-2.983-.012-4.025-.06c-1.038-.047-1.748-.212-2.369-.453a4.782 4.782 0 01-1.73-1.126 4.785 4.785 0 01-1.125-1.729c-.241-.62-.406-1.33-.454-2.37-.048-1.04-.059-1.373-.059-4.024s.011-2.984.06-4.025c.046-1.039.211-1.748.453-2.37a4.782 4.782 0 011.126-1.728 4.782 4.782 0 011.729-1.126c.62-.241 1.33-.406 2.37-.454 1.04-.047 1.373-.059 4.024-.059s2.984.012 4.025.06c1.039.047 1.748.212 2.37.453.65.245 1.24.63 1.728 1.126.497.488.881 1.077 1.126 1.729.242.62.407 1.33.454 2.37.048 1.04.059 1.373.059 4.024s-.011 2.984-.06 4.025z"
      fill={color}
    />
  </svg>
)

export default Instagram
