import React from "react"

// Styling
import colors from "../../styles/colors"

import iconMap from "./iconMap"

type IIconType = keyof typeof iconMap

export interface IIconProps {
  width?: number
  height?: number
  color?: string
  direction?: "right" | "left" | "down" | "up"
}

export type IProps = IIconProps & {
  icon: IIconType
}

const Icon: React.FCS<IProps> = ({
  icon,
  width,
  height,
  color = colors.white,
  direction,
  className,
}) => {
  const IconComponent = iconMap[icon]
  return (
    <IconComponent
      className={className}
      width={width}
      height={height}
      color={color}
      direction={direction}
    />
  )
}

export default Icon
