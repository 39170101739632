import Checkmark from "./Checkmark"
import Chevron from "./Chevron"
import Cross from "./Cross"
import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Loading from "./Loading"
import Linkedin from "./Linkedin"
import Play from "./Play"
import Twitter from "./Twitter"
import ArrowRight from "./ArrowRight"
import Company from "./Company"
import User from "./User"
import Download from "./Download"

const iconMap = {
  arrowRight: ArrowRight,
  checkmark: Checkmark,
  chevron: Chevron,
  cross: Cross,
  download: Download,
  facebook: Facebook,
  instagram: Instagram,
  loading: Loading,
  linkedin: Linkedin,
  play: Play,
  twitter: Twitter,
  company: Company,
  user: User,
}

export default iconMap
